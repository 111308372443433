import * as React from 'react';

interface Props {
	modificationTime?: string | null;
	publicationTime: string | null;
	previewImage?: string;
	canonicalUrl: string | undefined;
	metaDescription: string | undefined;
	title?: string;
	siteUrl?: string;
}

const ArticleStructuredData: React.FC<Props> = ({
	modificationTime,
	publicationTime,
	previewImage,
	canonicalUrl,
	metaDescription,
	title,
	siteUrl,
}) => {
	const articleData = {
		'@context': 'http://schema.org',
		'@type': 'Article',
		mainEntityOfPage: {
			'@type': 'webpage',
			'@id': canonicalUrl || '',
		},
		headline: title || '',
		description: metaDescription || '',
		image: previewImage + '/780x488-article-image',
		author: {
			'@type': 'Organization',
			name: 'Valio',
		},
		publisher: {
			'@type': 'Organization',
			name: 'Valio',
			logo: {
				'@type': 'ImageObject',
				url: siteUrl ? siteUrl.substring(0, siteUrl.lastIndexOf('/')) + '/logo/valioLogo-60px.png' : '',
				width: 90,
				height: 60,
			},
		},
		datePublished: publicationTime,
		dateModified: modificationTime || '',
	};

	return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(articleData) }} />;
};

export default ArticleStructuredData;
