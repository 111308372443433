import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { FrontHeader, FrontTitle, FrontSubTitle, ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { getHeroMediaSource } from 'utils/media';

const Title = styled(FrontTitle)`
	margin: 0;
`;

const PlainContent: React.FC<CmsContentStateProps & InjectedRoutableProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	titleHtml = '',
	leadText,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');

	return (
		<>
			<Hero size="medium" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY}>
				<FrontHeader>
					<Title colorTheme="light" dangerouslySetInnerHTML={{ __html: titleHtml }} />
					<FrontSubTitle colorTheme="light">{leadText}</FrontSubTitle>
				</FrontHeader>
			</Hero>

			<ContentWrapper>
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default injectRoutable(PlainContent);
