import * as React from 'react';
import { CmsContentAuthor } from '../../../interfaces';
import styled from 'styled-components';
import Image from 'styleguide/components/PicturesAndVideos/Image/Image';

const LessonAuthorWrapper = styled.div``;

const Author = styled.div`
	padding-bottom: 20px;

	img {
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}

	p {
		margin: 0;
		line-height: 1.25;
		font-size: 16px;

		&.author {
			font-weight: 600;
		}
	}
`;

const LessonAuthor: React.FC<CmsContentAuthor> = ({ name, title, image }) => {
	return (
		<LessonAuthorWrapper className="article-author">
			<Author>
				<Image src={`${image}/64x64-article-page-author`} />
				<p className="author">{name}</p>
				<p>{title}</p>
			</Author>
		</LessonAuthorWrapper>
	);
};

export default LessonAuthor;
