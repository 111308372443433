import * as React from 'react';
import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { LayoutProps } from 'common/layouts/interfaces';
import Article from './layouts/Article';
import SectionFront from './layouts/SectionFront';
import Wellbeing from './layouts/Wellbeing';
import Oddlygood from './layouts/Oddlygood';
import OddlygoodPortfolio from './layouts/OddlygoodPortfolio';
import Lesson from './layouts/Lesson';
import Basic from 'common/layouts/Basic';
import { CmsContentResourceState, CmsContentState } from './interfaces';
import { NavLinkItemProps } from 'common/components/Navigation/interfaces';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import LeadText from 'common/layouts/LeadText';
import ProfessionalLeadText from 'common/layouts/ProfessionalLeadText';
import Meta from 'common/components/Meta';
import Empty from 'common/layouts/Empty';
import {
	PageLoadEvent,
	pushPageLoad,
	ArticleLoadData,
	emptyRecipeLoadData,
	emptyProductLoadData,
} from 'utils/dataLayer';
import ArticleStructuredData from 'common/components/Resource/components/ArticleStructuredData';
import { getSiteUrl } from 'common/components/App/services';
import ImageHeader from './layouts/ImageHeader';
import PlainContent from './layouts/PlainContent';

export interface CmsContentStateProps extends LayoutProps, CmsContentState {
	title?: string;
	titleHtml?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	canonicalUrl: string | undefined;
	modificationTime?: string | null;
	publicationTime: string | null;
	hasContentSubNav?: boolean;
	headerTopOffset: number;
	topics: NavLinkItemProps[];
	themeColor?: string;
	siteUrl?: string;
	structuredData: string | null;
	metaDescription: string | undefined;
	dataLayerCategory: string | null;
}

function setDataLayer(resource: CmsContentStateProps | undefined) {
	if (!resource) {
		return;
	}
	const data: ArticleLoadData = {
		pageLabel: resource.contentFamily,
	};
	const event: PageLoadEvent = {
		event: 'pageLoad',
		pageType: 'article',
		pageCategory: resource.dataLayerCategory,
		...emptyRecipeLoadData(),
		...emptyProductLoadData(),
		...data,
	};
	pushPageLoad(event);
}

type Props = CmsContentStateProps;

class CmsContent extends Component<Props, {}> {
	public componentDidMount() {
		setDataLayer(this.props);
	}

	public render() {
		const { layout, ...rest } = this.props;
		const Layout = getLayoutComponent(layout);
		const {
			structuredData,
			title,
			heroImageDesktop,
			canonicalUrl,
			modificationTime,
			publicationTime,
			metaDescription,
			siteUrl,
		} = this.props;

		return (
			<>
				{this.renderMeta()}
				{!structuredData && (
					<ArticleStructuredData
						title={title}
						previewImage={heroImageDesktop}
						canonicalUrl={canonicalUrl}
						modificationTime={modificationTime}
						publicationTime={publicationTime}
						metaDescription={metaDescription}
						siteUrl={siteUrl}
					/>
				)}
				<Layout {...rest} />
				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</>
		);
	}

	private renderMeta() {
		const { canonicalUrl, title, leadText, heroImageDesktop } = this.props;
		return <Meta canonicalUrl={canonicalUrl} title={title} description={leadText} image={heroImageDesktop} />;
	}
}

function getLayoutComponent(layout?: string) {
	switch (layout) {
		case 'Article': {
			return Article;
		}
		case 'SectionFront': {
			return SectionFront;
		}
		case 'Wellbeing': {
			return Wellbeing;
		}
		case 'Oddlygood': {
			return Oddlygood;
		}
		case 'OddlygoodPortfolio': {
			return OddlygoodPortfolio;
		}
		case 'Lesson': {
			return Lesson;
		}
		case 'PlainContent': {
			return PlainContent;
		}
		case 'ProfessionalLeadText': {
			return ProfessionalLeadText;
		}
		case 'Basic': {
			return Basic;
		}
		case 'Empty': {
			return Empty;
		}
		case 'ImageHeader': {
			return ImageHeader;
		}
		case 'LeadText':
		default: {
			return LeadText;
		}
	}
}

const mapStateToProps: MapStateToProps<CmsContentStateProps, {}, State> = ({
	resource,
	app,
	routing,
	navigation,
}: State): CmsContentStateProps => {
	const {
		content,
		title,
		titleHtml,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		canonicalUrl,
		modificationTime,
		publicationTime,
		topics,
		themeColor,
		structuredData,
		metaDescription,
		dataLayerCategory,
	} = resource as CmsContentResourceState;
	const { contentFamily, leadText, author, isHealthCareContent } = content;
	const sites = (app.settings && app.settings.sites) || [];
	const siteUrl = getSiteUrl(routing, sites);
	return {
		title,
		titleHtml,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		canonicalUrl,
		modificationTime,
		publicationTime,
		contentFamily,
		leadText,
		author,
		headerTopOffset: navigation.topOffset,
		topics,
		isHealthCareContent,
		themeColor,
		siteUrl,
		structuredData,
		metaDescription,
		dataLayerCategory,
	};
};

export default connect(mapStateToProps)(CmsContent);
