import * as React from 'react';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import HighlightedLink from 'styleguide/components/Links/HighlightedLink/HighlightedLink';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { withWindow } from 'styleguide/helpers/window';
import SocialLinks from 'common/components/SocialLinks/SocialLinks';
import { CmsContentAuthor } from 'pagetypes/CmsContent/interfaces';
import { NavLinkItemProps } from 'common/components/Navigation/interfaces';
import ArticleAuthor from './ArticleAuthor';
import { ToggleMedia } from 'common/components/Media';

const DESKTOP_DEFAULT_TOP_VALUE = 60;

const ArticleDetailsWrapper = styled.section`
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
	padding: 0 10px;

	${media.tablet`
    width: 600px;
    padding: 0 30px;
  `};

	${media.desktop`
  	width: 160px;
    position: absolute;
    top: ${DESKTOP_DEFAULT_TOP_VALUE}px;
    left: 20px;
    margin-bottom: 0;
    padding: 0;
  `};

	${media.desktop1440`
    left: 50px;
  `};
`;

const ArticleCategories = styled.div`
	padding: 10px 0 20px;

	h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.25;
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		display: inline-block;
		margin: 8px 4px 0;

		${media.desktop`
  		display: block;
  		margin: 8px 0 0;
    `};
	}
`;

const ArticleSocialLinks = styled.div`
	border-top: 1px solid ${props => props.theme.colors.border};
	border-bottom: 1px solid ${props => props.theme.colors.border};

	${media.desktop`
  	padding: 20px 0;
    border-bottom: none;
  `};
`;

interface ArticleDetailsProps {
	title: string;
	author: CmsContentAuthor | null;
	shareImage?: string;
	canonicalUrl?: string;
	topics: NavLinkItemProps[];
}

interface ArticleDetailsState {
	wrapperStyles?: object;
}

class ArticleDetails extends React.Component<ArticleDetailsProps, ArticleDetailsState> {
	public state: ArticleDetailsState = {
		wrapperStyles: {},
	};

	private onResize = throttle(() => withWindow(this.onWindowResize), 250);

	public componentDidMount() {
		this.setState({ wrapperStyles: this.getDetailsStyles() });

		withWindow(w => {
			w.addEventListener('resize', this.onResize);
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('resize', this.onResize);
		});
	}

	public render() {
		const { title, author, shareImage, canonicalUrl, topics } = this.props;
		const { wrapperStyles } = this.state;

		return (
			<ArticleDetailsWrapper style={wrapperStyles}>
				{author && <ArticleAuthor {...author} />}
				{topics.length > 0 && (
					<ArticleCategories>
						<h4>Kategoriat:</h4>
						<ul>
							{topics.map((topic, index) => (
								<li key={`topic-${index}`}>
									<HighlightedLink href={topic.url} intense={true} wrapText={true}>
										{topic.title}
									</HighlightedLink>
								</li>
							))}
						</ul>
					</ArticleCategories>
				)}
				<ArticleSocialLinks>
					<ToggleMedia displayType="block" toggleType="hide" breakpoint="desktop">
						<SocialLinks title={title} url={canonicalUrl} imageUrl={shareImage} />
					</ToggleMedia>
					<ToggleMedia displayType="block" toggleType="show" breakpoint="desktop">
						<SocialLinks title={title} url={canonicalUrl} imageUrl={shareImage} />
					</ToggleMedia>
				</ArticleSocialLinks>
			</ArticleDetailsWrapper>
		);
	}

	private onWindowResize = (w: Window) => {
		this.setState({ wrapperStyles: this.getDetailsStyles() });
	};

	private getDetailsStyles() {
		let top = DESKTOP_DEFAULT_TOP_VALUE;

		withWindow(() => {
			const articleStartElement: HTMLElement = document.getElementsByClassName(
				'article-content-start'
			)[0] as HTMLElement;

			if (window.innerWidth >= mediaBreakpoints.desktop && articleStartElement) {
				top = articleStartElement.offsetTop;
			}
		});

		return {
			top: `${top}px`,
		};
	}
}

export default ArticleDetails;
