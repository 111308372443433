import * as React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import { media } from 'styleguide/helpers/media';
import { WIDGET_SPACING_SMALL } from 'common/components/Widgets/constants';
import { darken } from 'utils/color';
import { FormattedMessage } from 'react-intl';
import { HealthcareIcon } from 'styleguide/components/Icons';

const ProfessionalContentWrapper = styled.section`
	width: 100%;
	max-width: 720px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	border: 1px solid ${props => props.theme.colors.professionalContent};
	border-radius: 4px;

	${media.desktop`
		margin: 0 auto ${WIDGET_SPACING_SMALL}px;
  	max-width: 600px;
  `};

	${media.desktop1440`
  	max-width: 720px;
  `};
`;

const Header = styled.header`
	background: ${props => props.theme.colors.professionalContentBackground};
	padding: 30px 80px 20px 20px;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	line-height: 18px;
	position: relative;
	border-bottom: 1px solid ${props => props.theme.colors.professionalContent};

	> img {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	h3 {
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		color: ${props => props.theme.colors.white};
		background: ${props => props.theme.colors.professionalContent};
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		margin: 0;
		border-radius: 4px 0 0 0;
	}

	p {
		margin: 0;
	}
`;

const Content = styled.div`
	padding: 20px;

	h4 {
		margin: 0;
		font-size: 18px;
		font-weight: 600;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		width: 100%;
	}

	li {
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		line-height: 18px;

		${media.tablet`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `};

		& + li {
			border-top: 1px solid ${props => props.theme.colors.professionalContent};
		}

		a {
			font-weight: 900;
			margin-top: 10px;

			${media.tablet`
        flex: 1 0 50%;
        margin-top: 0;
        margin-left: 20px;
      `};
		}
	}
`;

const ProfessionalContentDisclaimer: React.FC<React.HTMLAttributes<HTMLElement>> = props => {
	return (
		<ProfessionalContentWrapper {...props}>
			<Header>
				<HealthcareIcon />
				<h3>
					<FormattedMessage id="page_cms_content_for_professionals" />
				</h3>
				<p>
					<FormattedMessage id="page_cms_content_for_professionals_description" />
				</p>
			</Header>
			<Content>
				<h4>
					<FormattedMessage id="page_cms_content_professional_want_more_info" />
				</h4>
				<ThemeConsumer>
					{theme => (
						<ul>
							<li>
								<span>
									<FormattedMessage id="page_cms_content_professional_newsletter_description" />
								</span>
								<ButtonLink
									fontStyle="secondary"
									size="small"
									href="/hyvinvointi/tilaa-ravitsemusuutisia/"
									backgroundColor={theme.colors.professionalContent}
									backgroundHoverColor={darken(theme.colors.professionalContent, 5)}>
									<FormattedMessage id="page_cms_content_professional_newsletter_order" />
								</ButtonLink>
							</li>
							<li>
								<span>
									<FormattedMessage id="page_cms_content_professional_materials_catalogue_description" />
								</span>
								<ButtonLink
									fontStyle="secondary"
									size="small"
									href="/ammattilaiset/materiaalipankki/"
									backgroundColor={theme.colors.professionalContent}
									backgroundHoverColor={darken(theme.colors.professionalContent, 5)}>
									<FormattedMessage id="page_cms_content_professional_materials_catalogue" />
								</ButtonLink>
							</li>
						</ul>
					)}
				</ThemeConsumer>
			</Content>
		</ProfessionalContentWrapper>
	);
};

export default ProfessionalContentDisclaimer;
