import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { getHeroMediaSource } from 'utils/media';
import { ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import ArticleHeader from './components/ArticleHeader';
import ArticleDetails from './components/ArticleDetails';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';
import { getFormattedFullDate } from 'utils/date';
import ArticleLead from './components/ArticleLead';
import ProfessionalContentDisclaimer from './components/ProfessionalContentDisclaimer';
import { useIntl } from 'react-intl';

const ArticleDate = styled.time`
	color: ${props => props.theme.colors.bodyTextLight};
	font-style: italic;
	font-size: 13px;
	line-height: 1.5;
	width: 100%;
	margin: 0 auto;
	padding: 0 10px;

	${media.desktop`
		max-width: 600px;
		padding: 0;
	`};

	${media.desktop1440`
		max-width: 720px;
	`};
`;

const Article: React.FC<CmsContentStateProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	canonicalUrl,
	title = '',
	titleHtml = '',
	contentFamily,
	leadText,
	author,
	topics,
	publicationTime,
	isHealthCareContent,
}) => {
	const intl = useIntl();
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'cms-content-article-hero');

	const articleDate = publicationTime || '';
	const date = new Date(articleDate);
	const articleDateFormatted = getFormattedFullDate(intl, date);

	return (
		<>
			<Hero src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY} />

			<ContentWrapper>
				<ContentThemeContext.Consumer>
					{contentTheme => (
						<ArticleHeader titleHtml={titleHtml} themeColor={contentTheme.color} contentFamily={contentFamily} />
					)}
				</ContentThemeContext.Consumer>
				<ArticleDetails
					title={title}
					author={author}
					topics={topics}
					canonicalUrl={canonicalUrl}
					shareImage={heroImageDesktop}
				/>

				{isHealthCareContent && <ProfessionalContentDisclaimer className="article-content-start" />}

				{publicationTime && (
					<ArticleDate className="article-content-start" dateTime={articleDate}>
						{articleDateFormatted}
					</ArticleDate>
				)}

				<ArticleLead>{leadText}</ArticleLead>

				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default Article;
