import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import ContentTag from 'styleguide/components/Tags/ContentTag/ContentTag';
import { WithContentFamily } from '../../../../../common/types/contentFamily';

const ArticleHeaderWrapper = styled.section<{ themeColor?: string }>`
	width: 100%;
	padding: 30px 10px;
	align-self: flex-start;
	position: relative;

	${media.tablet`
    width: 640px;
    padding: 30px 50px;
    margin: -135px auto 0;
    border-radius: 2px 2px 0 0;
  	background: ${props => props.theme.colors.bodyBackground};  
		min-height: 135px;
  `};

	${media.desktop`
    width: 670px;
		min-height: 170px;
	  padding: 50px;
    margin: -110px 10px 0;
  `};

	h1 {
		font-size: 24px;
		font-weight: 600;
		line-height: 26px;
		margin: 0;
		font-family: ${({ theme }) => theme.fonts.primary};
		color: ${({ themeColor, theme }) => themeColor || theme.colors.bodyText};

		${media.desktop`
  		font-size: 36px;
      line-height: 1;
    `};
	}

	.content-tag {
		position: absolute;
		top: -12px;
		left: 0;

		${media.tablet`
      left: 30px;
    `};

		${media.desktop`
  		top: -15px;
    `};
	}
`;

interface ArticleHeaderProps extends WithContentFamily {
	themeColor: string;
	titleHtml: string;
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({ themeColor, titleHtml, contentFamily }) => {
	return (
		<ArticleHeaderWrapper themeColor={themeColor}>
			{contentFamily && (
				<ContentTag color={themeColor} className="content-tag">
					{contentFamily}
				</ContentTag>
			)}
			<header>
				<h1 dangerouslySetInnerHTML={{ __html: titleHtml }} />
			</header>
		</ArticleHeaderWrapper>
	);
};

export default ArticleHeader;
