import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const LessonLead = styled.p`
	font-size: 20px;
	line-height: 1.2;
	width: 100%;
	margin: 14px auto;
	padding: 0px 8px;

	${media.tablet`
		padding: 0;
		max-width: 600px;
	`}

	${media.desktop`
	  max-width: none;
		font-size: 28px;
		padding-left: 240px;
		margin: 0px auto 28px auto;
	`};

	${media.desktop1440`
	`};
`;

export default LessonLead;
