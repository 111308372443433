import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { getHeroMediaSource } from 'utils/media';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';
import FramedHeader from 'common/components/FramedHeader';
import { ResourceState } from 'common/components/Resource/reducers/reducers';
import { Icon } from 'utils/import';

type ImageHeaderResourceState = Pick<ResourceState, 'previewImage'>;

export interface ImageHeaderProps extends CmsContentStateProps {
	showContentFamily?: boolean;
	backgroundColor?: string;
	textColor?: string;
	highlightIcon?: ResourceState['highlightIcon'];
}
const ImageHeader: React.FC<ImageHeaderProps & ImageHeaderResourceState & InjectedRoutableProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	backgroundColor,
	highlightIcon,
	textColor,
	previewImage,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');

	return (
		<>
			<Hero size="medium" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY} />

			<ContentWrapper>
				<ContentThemeContext.Consumer>
					{contentTheme => (
						<FramedHeader
							themeColor={contentTheme.color}
							showContentFamily={false}
							backgroundColor={backgroundColor}
							textColor={textColor}
							variant="imageheader"
							highlightImage={previewImage}
							highlightIcon={<Icon iconName={highlightIcon} />}
							showBorder={false}
						/>
					)}
				</ContentThemeContext.Consumer>
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default injectRoutable(ImageHeader);
