import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { getHeroMediaSource } from 'utils/media';
import { ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import LessonHeader from './components/LessonHeader';
import LessonDetails from './components/LessonDetails';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';
import LessonLead from './components/LessonLead';

/*
		${media.desktop`
		padding-left: 240px;
	`};
*/

const CustomOrderedListWrappedWidgetZone = styled(WidgetZone)`
	${media.tablet`
		padding-left: 0;

		& > div {
			max-width: 600px;			
			margin: 0 auto;
		}
	`};

	${media.desktop`
	padding-left: 240px;

	& > div {
		max-width: none;			
	}
`};

	& ul {
		list-style: none;
	}

	& h3 {
		font-size: 85% !important;
		text-transform: uppercase;
	}

	& ol {
		padding-left: 0 !important;
	}

	& ol:not([start]) {
	}

	& ol > li {
		display: flex;
		align-items: flex-start;
		counter-increment: list-item;
		padding-bottom: 7px;
	}

	& ol > li::before {
		content: counter(list-item);
		font-weight: bold;
		font-size: 39px;
		line-height: 31px;
		width: 1.3em;
		color: #910048;
		flex: none;
	}

	${media.desktop`
		& ol > li::before {
			font-size: 50px;
			line-height: 40px;
		}
	`};

	& ol > li p {
	}
`;

const LessonLayout: React.FC<CmsContentStateProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	canonicalUrl,
	title = '',
	titleHtml = '',
	contentFamily,
	author,
	leadText,
	topics,
	themeColor,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'cms-content-article-hero');

	themeColor = themeColor || '#910048';

	return (
		<>
			<Hero src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY} />

			<ContentWrapper>
				<ContentThemeContext.Consumer>
					{contentTheme => (
						<LessonHeader titleHtml={titleHtml} themeColor={contentTheme.color} contentFamily={contentFamily} />
					)}
				</ContentThemeContext.Consumer>
				<LessonDetails
					title={title}
					author={author}
					topics={topics}
					canonicalUrl={canonicalUrl}
					shareImage={heroImageDesktop}
					themeColor={themeColor}
				/>

				<LessonLead>{leadText}</LessonLead>

				<CustomOrderedListWrappedWidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default LessonLayout;
