import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { ContentWrapper } from 'common/components/General';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { getHeroMediaSource } from 'utils/media';
import OddlyTitle from 'common/components/Oddlygood/OddlyTitle';
import Image from '../../../../styleguide/components/PicturesAndVideos/Image';
import { ImageSource } from 'styleguide/interfaces';
import { TEXT_WIDTH, TEXT_WIDTH_NARROW, WIDGET_SPACING_LARGE } from 'common/components/Widgets/constants';

interface HeaderProps {
	isImagePresent: boolean;
}

const Header = styled('header')<HeaderProps>`
	background-color: ${props => props.theme.colors.brandPrimary};
	width: 100%;
	position: relative;
	padding: 0 25px ${props => (props.isImagePresent ? '30vw' : 0)};
	margin-bottom: ${props => (props.isImagePresent ? '25vw' : 0)};

	${media.desktop<HeaderProps>`
    padding: 0 25px ${props => (props.isImagePresent ? '155px' : 0)};
    margin-bottom: ${props => (props.isImagePresent ? '240px' : 0)};
  `};
`;

const HeaderContent = styled.div`
	max-width: 780px;
	margin: 50px auto 25px;

	${media.desktop`
    margin: 30px auto 150px;
  `};
`;

const ArticleImageWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	width: 100%;
	max-width: 780px;
	padding: 0 10px;
	text-align: center;

	> img {
		width: auto;
		max-width: 100%;
	}

	${media.phone`
		> img {
			width: 75vw;
		}
  `};

	${media.tablet`
		> img {
			width: 75vw;
		}
  `};

	${media.desktop`
    padding: 0;
		> img {
			width: auto;
			max-height: 550px;
		}
  `};
`;

const LeadTextWrapper = styled.div`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	font-size: 16px;
	font-weight: bold;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};

	margin-top: ${WIDGET_SPACING_LARGE}px;

	${media.tablet`
	padding: 0;
`};

	${media.desktop`
	font-size: 18px;
	padding: 0;
`};

	${media.desktop1440`
	max-width: ${TEXT_WIDTH}px;
`};
`;

const Wellbeing: React.FC<CmsContentStateProps & InjectedRoutableProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	titleHtml = '',
	leadText,
	hasContentSubNav,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');

	const imageSrc = heroMedia.mediaSrc as ImageSource[];

	return (
		<>
			<Header isImagePresent={true}>
				<HeaderContent>
					<OddlyTitle title={titleHtml} />
				</HeaderContent>

				<ArticleImageWrapper>
					<Image src={imageSrc} alt="" />
				</ArticleImageWrapper>
			</Header>

			<ContentWrapper>
				<LeadTextWrapper>{leadText}</LeadTextWrapper>
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default injectRoutable(Wellbeing);
