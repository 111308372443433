import * as React from 'react';
import { CmsContentStateProps } from '../../CmsContent';
import { FrontHeader, FrontSubTitle, ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { getHeroMediaSource } from 'utils/media';
import OddlySubTitle from 'common/components/Oddlygood/OddlySubTitle';

const PageTitle = styled.div`
	margin: 3em 1em 0em 1em !important;
`;

const OddlygoodPortfolio: React.FC<CmsContentStateProps & InjectedRoutableProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	titleHtml = '',
	leadText,
	hasContentSubNav,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');

	return (
		<>
			<Hero size="medium" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY} />

			<PageTitle>
				<OddlySubTitle title={titleHtml} />
			</PageTitle>
			<FrontHeader>
				<FrontSubTitle colorTheme="light">{leadText}</FrontSubTitle>
			</FrontHeader>

			<ContentWrapper>
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default injectRoutable(OddlygoodPortfolio);
