import * as React from 'react';
import { FrontHeader, FrontTitle, FrontSubTitle, ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import { CmsContentStateProps } from '../CmsContent';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { getHeroMediaSource } from 'utils/media';

const SectionFrontTitle = styled(FrontTitle)`
	margin: 0;
`;

const SectionFront: React.FC<CmsContentStateProps> = ({
	heroImageDesktop,
	heroImageMobile,
	heroImageOffsetY,
	heroMediaType,
	titleHtml = '',
	leadText,
	themeColor,
}) => {
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');
	return (
		<>
			<Hero size="small" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY}>
				<FrontHeader>
					<SectionFrontTitle colorTheme="dark" color={themeColor} dangerouslySetInnerHTML={{ __html: titleHtml }} />
					<FrontSubTitle colorTheme="dark" color={themeColor}>
						{leadText}
					</FrontSubTitle>
				</FrontHeader>
			</Hero>

			<ContentWrapper>
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default SectionFront;
